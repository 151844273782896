.home {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
}
.otp-bg {
  background-image: url(../images/splash-screen.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.hover-notallowed:hover{
  cursor: not-allowed;
}
.bg-themeorange {
  background-color: #ed4f2a !important;
}
.bg-themeorange:hover {
  background-color: rgb(209, 64, 31) !important;
}
.text-themeorange {
  color: #df5a28 !important;
}
.header-link {
  color: #6d6c6a;
  margin: 0 15px;
  padding: 15px 0;
  font-weight: 500;
}
.header-link:hover {
  color: #df5a28;
  text-decoration: none;
}
.inner {
  min-height: 90vh;
  background: rgba(0, 0, 0, 0.4);
}
.facebook-btn {
  background: #3b5998;
}
.google-btn {
  background: #ff6464;
}
.hover-pointer {
  cursor: pointer;
}
.cross-btn{
  z-index: 999;
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 0.7;
}

.img-container>svg{
  display: none;
}

.img-container:hover>svg{
  display: block;
}

.video-container>svg{
  display: none;
}

.video-container:hover>svg{
  display: block;
}

.image-check{
  opacity: 0.3;
}
.box-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

#container {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="230" ><defs><linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" stop-color="%23ad3" /><stop offset="100%" stop-color="%23add" /></linearGradient></defs><path d="M0 10 C30 28 38 0 64 10 L64 0 L0 0 Z"  fill="whitesmoke"/></svg>'),
    linear-gradient(to bottom right, #df5a28, #df5a28);

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.rtl-btn {
  position: absolute;
  right: 1px;
  top: 10px;
}

button:focus {
  outline: none !important;
}

.side-icons {
  padding: 2px;
  background-color: #df5a28;
  color: white;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.7), 3px 2px 1px rgba(0, 0, 0, 0.4), 1px 3px 1px rgba(0, 0, 0, 0.4),
    1px 4px 1px rgba(0, 0, 0, 0.4);
}

.expense-box:hover {
  background: rgba(0, 0, 0, 0.05);
}

.secondary {
  font-size: 12px;
  color: grey;
}
.sales-detail {
  width: 50%;
}
@media (max-width: 1200px) {
  .sales-detail {
    width: 75% !important;
  }
}

@media (max-width: 970px) {
  .sales-detail {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .custom-btn {
    width: 100% !important;
  }
}

.card-bg {
  background-image: url('./expensebg.jpg');
  object-fit: contain;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.expense {
  width: 50%;
}
.searchInvoice {
  background-image: url(../images/splash-screen.png);
  background-repeat: no-repeat;
}

.list-hover:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.termlogo {
  width: 30%;
  position: absolute;
  top: 40px;
  left: 35%;
}
.field-container {
  width: 100%;
  display: flex;
}
.signup-fields {
  width: 50%;
  padding: 5px;
}

@media (max-width: 1200px) {
  .ghost {
    height: 25px;
  }
}

@media (max-width: 1400px) {
  .searchInvoice,
  .salesReport,
  .profitLoss {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .expense,
  .searchInvoice,
  .ghost {
    width: 75%;
  }
}

@media (max-width: 900px) {
  .salesReport,
  .profitLoss {
    width: 75%;
  }
}

@media (max-width: 700px) {
  .salesReport {
    width: 100%;
  }
  .termlogo {
    width: 40%;
    position: absolute;
    top: 40px;
    left: 30%;
  }
}

@media (max-width: 600px) {
  .expense,
  .searchInvoice,
  .profitLoss {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .field-container {
    display: block;
  }
  .signup-fields {
    width: 100%;
  }
}

/********************************************************/

#bg {
  background-image: url(./homebg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
#coverflow {
  background: transparent;
  clip-path: polygon(51% 9%, 72% 0, 100% 26%, 98% 50%, 100% 76%, 71% 100%, 52% 97%, 0 100%, 0 100%, 0 0);
}
.home {
  background-image: url(./homebg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* clip-path: polygon(47% 5%, 81% 0, 100% 0, 100% 100%, 47% 94%, 13% 100%, 0 62%, 6% 45%, 0 21%, 19% 0); */
  clip-path: polygon(48% 4%, 84% 0, 100% 0, 100% 100%, 87% 100%, 47% 91%, 10% 100%, 0% 70%, 0% 35%, 12% 0);

}

.carousel-container {
  display: flex;
  align-items: center;
}

.small-carousel {
  display: none;
}
#wrapper {
  width: 300px;
  padding-left: 22px;
  transform: rotateY(180deg);
  float: left;
}

#bar {
  list-style: none;
}
#bar li{
  margin-top: 7px;
}

#bar li div.bottom {
  -moz-box-shadow: 0 10px 10px hsla(0, 0%, 0%, 0.2);
  -webkit-box-shadow: 0 10px 30px hsla(0, 0%, 0%, 0.2);
  box-shadow: 0 10px 30px hsla(0, 0%, 0%, 0.2);
}

#bar li div.top {
  background-color: rgba(7, 102, 112, 0.5);
  position: relative;
  float: left;
  margin-left: 10px;
  width: 40px;
  height: 60px;
  -moz-border-radius: 40px/60px;
  -webkit-border-radius: 40px 60px;
  border-radius: 40px/60px;
  transition-property: margin-left;
  transition-duration: 500ms;
  -webkit-transition-property: margin-left;
  -webkit-transition-duration: 500ms;
}
#bar li div.top img {
  margin-left: 10px;
  margin-top: 5px;
  transform: rotateY(-180deg);
  border-radius: 50%;
}

#bar li div.bottom {
  position: relative;
  margin: 5px 0;
  width: 50px;
  height: 60px;
  -moz-border-radius: 40px/60px;
  -webkit-border-radius: 40px 60px;
  border-radius: 40px/60px;
  background: -moz-linear-gradient(-90deg, #97a7a9, #b8cbcd);
  background: -webkit-gradient(linear, 0 top, 0 bottom, from(#97a7a9), to(#b8cbcd));
  transition-property: width;
  transition-duration: 500ms;
  -webkit-transition-property: width;
  -webkit-transition-duration: 500ms;
}
#bar li div.bottom div.infobox {
  padding: 5px 0 0 200px;
  font-size: 13px;
  font-weight: bold;
  color: whitesmoke;
  transform: rotateY(-180deg);
  transition-property: color;
  transition-duration: 500ms;
  -webkit-transition-property: color;
  -webkit-transition-duration: 500ms;
}

#bar li:hover div.bottom div.infobox {
  color: #eee;
  text-shadow: 0px 5px 5px #111;
}
#iphone div.top {
  z-index: 99;
}
#iphone div.bottom {
  z-index: 98;
  height: 150px;
}
#iphone:hover div.top {
  z-index: 999;
  background-color: #1f81ac;
  margin-left: 160px;
}
#iphone:hover div.bottom {
  z-index: 998;
  background-color: #1a6c90;
  width: 200px;
  background: -moz-linear-gradient(-90deg, #1a6c90, #14506b);
  background: -webkit-gradient(linear, 0 top, 0 bottom, from(#1a6c90), to(#14506b));
}

#macbook div.top {
  z-index: 97;
}
#macbook div.bottom {
  z-index: 96;
  height: 150px;
}
#macbook:hover div.top {
  z-index: 997;
  background-color: #bc003c;
  margin-left: 160px;
}
#macbook:hover div.bottom {
  z-index: 996;
  background-color: #9d0032;
  width: 200px;
  background: -moz-linear-gradient(-90deg, #9d0032, #7a0027);
  background: -webkit-gradient(linear, 0 top, 0 bottom, from(#9d0032), to(#7a0027));
}

#ipod div.top {
  z-index: 95;
}
#ipod div.bottom {
  z-index: 94;
  height: 200px;
}
#ipod:hover div.top {
  z-index: 995;
  background-color: #d98f23;
  margin-left: 106px;
}
#ipod:hover div.bottom {
  z-index: 994;
  background-color: #b6781e;
  width: 140px;
  background: -moz-linear-gradient(-90deg, #b6781e, #916018);
  background: -webkit-gradient(linear, 0 top, 0 bottom, from(#b6781e), to(#916018));
}

#cinema div.top {
  z-index: 93;
}
#cinema div.bottom {
  z-index: 92;
  height: 100px;
}
#cinema:hover div.top {
  z-index: 993;
  background-color: #7da864;
  margin-left: 110px;
}
#cinema:hover div.bottom {
  z-index: 992;
  background-color: #698d54;
  width: 150px;
  background: -moz-linear-gradient(-90deg, #698d54, #506b40);
  background: -webkit-gradient(linear, 0 top, 0 bottom, from(#698d54), to(#506b40));
}

#macmini div.top {
  z-index: 91;
}
#macmini div.bottom {
  z-index: 90;
  height: 120px;
}
#macmini:hover div.top {
  z-index: 991;
  background-color: #3f1150;
  margin-left: 130px;
}
#macmini:hover div.bottom {
  z-index: 990;
  background-color: #340e43;
  width: 170px;
  background: -moz-linear-gradient(-90deg, #340e43, #1a0721);
  background: -webkit-gradient(linear, 0 top, 0 bottom, from(#340e43), to(#1a0721));
}

@media (max-width: 700px) {
  .carousel-container {
    display: none;
  }
  .small-carousel {
    display: flex;
    align-items: center;
    position: fixed;
    min-height: 500px;
    z-index: -1;
  }
}

@media (max-width: 450px) {
  .small-carousel {
    display: none;
  }
}
#footer {
  background: rgb(29, 28, 28);
  clip-path: polygon(47% 0, 100% 26%, 100% 99%, 0 100%, 0 35%);
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tooltip-inner {
  max-width: 300px !important;
}

.smcx-widget{
  width: 100%;
  max-width: 100% !important;
  height: 90vh !important;
}
.smcx-widget>.smcx-iframe-container{
  width: 100%;
  max-width: 100% !important;
  height: 100% !important;
}