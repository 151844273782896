html {
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100%;
  background-color: #f7f7fc;
}

.divError {
  display: flex;
  background-color: #fff3f8;
  border: 2px solid #c30052;
  border-radius: 0.875rem;
  padding: 0.2rem;
}

.errorLine {
  color: #c30052;
  font-size: 0.875rem;
  font-weight: 500;
}

.sm-headline {
  font-size: 0.875rem;
  font-weight: 500;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
  background-color: #eff0f6;
  border-radius: 0.875rem;
  font-size: 1.5rem;
  padding: 0.3rem;
}

.inputContainerIcon {
  margin: auto 2px;
}
/* Style for when MUI Button is disabled */
.MuiButton-contained.Mui-disabled {
  color: #fff !important;
  background-color: #eeaf9f !important;
}

.flexedCenterContainer {
  /* border: 1px solid black; */
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  margin: auto;
  height: 75%;
}

.formTitle {
  text-align: start;
  font-weight: 600;
  margin-bottom: 0.75rem;
  /* margin-left: 8px; */
  margin-top: 1.125rem;
}

.subtitle {
  font-weight: 400;
  color: #afafbd;
  font-size: 0.875rem;
}

.flexedCenterDiv {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  justify-content: space-evenly !important;
}
.MuiAutocomplete-option {
  cursor: pointer;
  display: flex;
  outline: 0;
  box-sizing: border-box;
  min-height: fit-content !important;
  align-items: left !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-left: 1px !important;
  padding-bottom: 2px !important;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent;
}

.MuiExpansionPanel-rounded {
  border-radius: 1rem !important;
  margin: 0.5rem 0 !important;
}

.nohover:hover {
  color: #df5a28 !important;
}
.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
.MuiPickersDatePickerRoot-toolbar {
  align-items: center !important;
  flex-direction: column;
}
.MuiPickersToolbarText-toolbarBtnSelected {
  color: #000;
  font-size: x-large !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiInputBase-input.Mui-disabled {
  color: black !important;
}
.MuiTableCell-root {
  display: table-cell;
  padding: 8px !important;
  font-size: 0.85rem !important;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: normal;
  vertical-align: inherit;
}
.Toastify__toast {
  margin-top: 50px !important;
  overflow: auto !important;
}

.MuiAutocomplete-option[data-focus='true'] {
  background-color: rgb(152, 131, 131) !important;
}
.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  margin-bottom: 0rem !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.Toastify__toast-container--top-right {
  top: 6em;
  right: 1em;
}
.makeStyles-loginroot-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.MuiFormControl-root {
  padding: 0 10px !important;
}
.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  width: 100%;
}

.DateRangePicker_picker {
  z-index: 100000000000 !important;
  background-color: #fff;
  position: absolute;
}

.CalendarMonth {
  background: #efd6d6 !important;
  text-align: center;
  vertical-align: top;
  user-select: none;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 80px !important;
  vertical-align: middle;
}
.DateInput_input {
  font-weight: 200;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 5px 4px 2px !important;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 15px !important;
  width: 24px;
}

/* .MuiButton-label{
  color: $textInputPrimary;
}

.MuiButton-root {
  width: 50%;
  height: 60px;
  border-radius: 8px;
  box-shadow: none;
} */
.vendorHeadingBlock {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}
.alertDeleteDialog p {
  color: #444 !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  margin-left: 0%;
  transform: translate(21px, -5px) scale(0.7) !important;
}
.MuiDrawer-root MuiDrawer-docked makeStyles-drawer-18 {
  background-color: #df5a28 !important;
  color: #df5a28 !important;
}

.MuiInputBase-root {
  font-size: 0.9rem !important;
}

/* .MuiDialog-paper {
    width: 22%;
    position: relative;
    overflow-y: auto;
}
.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 0px 0px !important;
}
.MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 0px 0px !important;
    overflow-y: auto;
}
.MuiDialog-paper {
    margin: 32px;
    padding: 0px !important;
    position: relative;
    overflow-y: auto;
} */
.MuiInputLabel-root {
  display: block;
  margin-left: 10px;
  transform-origin: top left;
}
.MuiPaper-root MuiDialog-paper MuiDialog-paperWidthSm {
  max-width: 450px !important;
}

.termsConditions li {
  margin: 21px 0;
}

@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    margin: 5px;
  }
  .MuiFormControl-marginNormal {
    margin: 10px;
  }
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  /* button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    padding: 0 5px;
    line-height: normal;
    font-size: 15px;
    margin: 5px;
    min-width: auto;
  } */
  .MuiDrawer-root MuiDrawer-docked makeStyles-drawer-18 {
    background-color: #df5a28 !important;
    color: #df5a28 !important;
  }
  .MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl {
    top: 0;
    left: 5 !important;
    margin-left: 10px !important;
    transform: translate(0, 24px) scale(1);
  }
  .MuiPaper-root
    MuiDialog-paper
    MuiPickersModal-dialogRoot
    MuiDialog-paperScrollPaper
    MuiDialog-paperWidthSm
    MuiPaper-elevation24
    MuiPaper-rounded {
    max-width: 450px !important;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1299px) {
  .makeStyles-copyright-18 {
    bottom: 0 !important;
    background: #d84314;
    padding: 10px;
    z-index: 1000;
  }
  .MuiTypography-colorTextSecondary {
    color: #fff !important;
  }
  .MuiButton-root {
    font-size: 12px !important;
  }
  .MuiButton-startIcon {
    display: none !important;
  }
  .MuiDrawer-root MuiDrawer-docked makeStyles-drawer-18 {
    background-color: #df5a28 !important;
    color: #df5a28 !important;
  }
  .MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl {
    top: 0;
    left: 5 !important;
    margin-left: 10px !important;
    transform: translate(0, 24px) scale(1);
  }
  .MuiInputLabel-formControl {
    font-size: 0.9rem !important ;
  }
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .MuiGrid-item {
    margin-bottom: 10px !important;
  }
  .headerDatePicker {
    width: 70%;
  }
  .makeStyles-copyright-18 {
    bottom: 0 !important;
    background: #d84314;
    padding: 10px;
    z-index: 1000;
  }
  .MuiTypography-colorTextSecondary {
    color: #fff !important;
  }
  .MuiAutocomplete-popper {
    z-index: 1000;
  }
  .MuiDrawer-root MuiDrawer-docked makeStyles-drawer-18 {
    background-color: #df5a28 !important;
    color: #df5a28 !important;
  }
  .MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl {
    top: 0;
    left: 5 !important;
    margin-left: 10px !important;
    transform: translate(0, 24px) scale(1);
  }
}

@media only screen and (max-width: 599px) {
  .MuiGrid-item {
    margin-bottom: 10px !important;
  }
  .headerDatePicker {
    width: 100%;
  }
  .makeStyles-copyright-18 {
    bottom: 0 !important;
    background: #d84314;
    padding: 10px;
    z-index: 1000;
  }
  .MuiTypography-colorTextSecondary {
    color: #fff !important;
  }
  .categoryTableSearch {
    width: auto !important;
    top: 9px;
    position: relative;
    right: -10px;
    margin-bottom: 10px;
  }

  .vendorHeadingBlock {
    flex-direction: column;
  }
  .headerDate {
    font-size: 12px;
  }
  #toggleHeaderButton {
    display: none;
  }
  #headerMainHeading {
    text-align: left;
  }
  #modalDynamicHeight {
    height: 90%;
  }
}
